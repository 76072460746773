import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Avatar from "./Avatar";
import { testimonialPhoto01 } from "../assets";

function TestimonialsSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        {/* <div className="container"> */}
        <div className="gray-border gray-background relative rounded-md shadow-sm">
          <div className="absolute right-0 top-0 mr-5 mt-3 font-serif text-9xl text-blue-200 opacity-75">“</div>
          <div className="relative px-6 pb-6 pt-16 text-center md:px-12 md:pb-10">
            <div className="mb-3">
              <div className="inline-flex items-center rounded-lg bg-orange-100 px-2 py-1 text-yellow-500">
                <StarIcon className="inline-block h-5 w-5" />
                <StarIcon className="inline-block h-5 w-5" />
                <StarIcon className="inline-block h-5 w-5" />
                <StarIcon className="inline-block h-5 w-5" />
                <StarIcon className="inline-block h-5 w-5" />
              </div>
            </div>
            <blockquote className="mx-auto lg:w-2/3 xl:w-1/2">
              <p className="mb-5 text-xl font-semibold leading-8">
                Whisperscript is a game-changer for my design research interviews. The AI-powered app quickly
                transcribes my audio recordings with impressive accuracy, saving me valuable time and allowing me to
                focus on the insights that matter.
              </p>
              <footer className="space-y-4">
                <Avatar image={testimonialPhoto01} size="md" className="mx-auto" />
                <div>
                  <span className="text-lg font-semibold text-blue-600">Danh P.</span>
                  <div className="mx-auto my-1 h-1 w-10 rounded-lg bg-blue-200" />
                  <p className="font-medium text-gray-500">Project Pluto Studio.</p>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default TestimonialsSection2;
