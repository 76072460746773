import { FilmIcon, HeartIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import React from "react";
import { wave } from "../assets";
import Icon from "./Icon";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection(props) {
  const items = [
    {
      title: "Artist-Centered Approach",
      description:
        "Founded by Jonathan Ho and Kai Shimada, both with professional backgrounds in film audio post-production and technical expertise, Wavery is built with a deep understanding of the artistic process. We strive to create tools that empower creatives to focus on their art, without being hindered by technical limitations.",
      icon: FilmIcon,
      iconColor: "text-green-500",
    },
    {
      title: "Cutting-Edge Technology",
      description:
        "At Wavery, we harness the power of advanced machine learning to deliver state-of-the-art audio tools. Our solutions are designed to integrate seamlessly into your existing workflows, enhancing productivity and ensuring top-quality results every time.",
      icon: HeartIcon,
      iconColor: "text-red-500",
    },
    {
      title: "Uncompromising Privacy",
      description:
        "We prioritize your privacy by performing all audio processing on your local device. This means your sensitive data never leaves your control, offering peace of mind without sacrificing performance or accuracy.",
      icon: LockClosedIcon,
      iconColor: "text-blue-500",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-16">
        <div>
          <Icon src={wave} alt="Wavery Logo" width={80} height={80} />
          {/* <FilmIcon className="inline-block w-16 h-16 mb-5 text-blue-600" /> */}
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
            className="pt-3 lg:w-1/2"
          />
        </div>
        <div className="flex space-x-2">
          <div>
            <img
              src={props.leftImage}
              alt="by Silas Baisch on Unsplash"
              className="max-h-96 w-auto rounded-lg object-contain"
            />
          </div>
          <div>
            <img
              src={props.centerImage}
              alt="by detait on Unsplash"
              className="max-h-96 w-auto rounded-lg object-contain"
            />
          </div>
          <div>
            <img
              src={props.rightImage}
              alt="by hoch3media on Unsplash"
              className="max-h-96 w-auto rounded-lg object-contain"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-16 md:grid-cols-3">
          {items.map((item, index) => (
            <div key={index}>
              <h3 className="mb-2 flex items-center space-x-2 text-lg font-bold uppercase tracking-wide">
                <item.icon className={"inline-block h-5 w-5" + (item.iconColor ? ` ${item.iconColor}` : "")} />
                <span>{item.title}</span>
              </h3>
              <p className="leading-relaxed text-gray-400/[.86]">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
