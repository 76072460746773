import { Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CogIcon,
  LightBulbIcon,
  LockClosedIcon,
  UserIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Building2Icon, CircleDollarSignIcon, MailIcon, MessageCircleQuestionIcon, Package } from "lucide-react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { products } from "../constants/";
import { useAuth } from "./../util/auth";
import { Link } from "./../util/router";
import AnimatedChevron from "./AnimatedChevron";
import Button from "./Button";
import HoverDropdown from "./HoverDropdown";
import NavBarProduct from "./NavBarProduct";
import Section from "./Section";
import WaveryLogo from "./WaveryLogo";

const classes = {
  navLink: "font-semibold text-gray-100 inline-flex items-center space-x-1 h-8 px-4 group-hover:text-waveryBlue py-6",
  hamburgerMenu: "text-white border-none select-none !p-0 [-webkit-tap-highlight-color:transparent]",
};

export default function Navbar(props) {
  const auth = useAuth();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        label: "Apps",
        icon: Package,
        submenu: [
          {
            label: "Apps",
            items: products,
          },
        ],
      },
      {
        label: "Pricing",
        to: "/pricing",
        icon: CircleDollarSignIcon,
      },
      {
        label: "Resources",
        submenu: [
          {
            label: "Quicklinks",
            items: [
              {
                label: "FAQ",
                description: "Frequently Asked Questions",
                to: "/faq",
                icon: MessageCircleQuestionIcon,
              },
              {
                label: "About Wavery",
                description: "Meet the team behind Wavery",
                to: "/about",
                icon: Building2Icon,
              },
            ],
          },
          {
            label: "Support",
            items: [
              {
                label: "Contact Us",
                description: "Get in touch with our team",
                to: "/contact",
                icon: MailIcon,
              },
              {
                label: "Suggest Features",
                description: "Share your feature ideas with us",
                to: "/contact",
                icon: LightBulbIcon,
              },
            ],
          },
        ],
      },
    ],
    [],
  );

  const accountItems = useMemo(
    () =>
      auth.user
        ? // Signed In
          [
            {
              label: "Settings",
              to: "/settings/general",
              icon: CogIcon,
            },
            {
              label: "Log Out",
              onClick: () => auth.signout(),
              icon: LockClosedIcon,
            },
          ]
        : // Signed Out
          [
            {
              label: "Sign Up",
              to: "/auth/signup",
              icon: UserPlusIcon,
            },
            {
              label: "Log In",
              to: "/auth/signin",
              icon: UserIcon,
            },
          ],
    [auth],
  );

  return (
    <Section bgColor={props.bgColor}>
      <header className="container z-50 flex items-center justify-between py-4">
        <HomeButton />

        {/* Desktop: Navbar Items */}
        <div className="hidden items-center gap-4 lg:flex">
          <NavBarLinks items={menuItems} />

          <div className="mx-1 h-6 w-px bg-white/20" />

          {/* Account */}
          <div className="flex w-[117px] items-center justify-center">
            {auth.user ? (
              // Signed In
              <DropdownMenu
                items={accountItems}
                header={
                  auth.user.email && (
                    <div className="px-3.5 py-3">
                      <div className="text-xs text-white/50">Logged in as:</div>
                      <div class="mt-0.5 truncate font-semibold">{auth.user.email}</div>
                    </div>
                  )
                }
              >
                Account
              </DropdownMenu>
            ) : (
              // Signed Out
              <Link to="/auth/signin" className={classes.navLink}>
                Log In
              </Link>
            )}
          </div>
        </div>

        {/* Mobile: Hamburger Menu Open Button */}
        <Button
          aria-label="Hamburger Menu Open Button"
          variant="custom"
          size="sm"
          placement="Navbar-hamburgerOpen"
          onClick={() => setMobileNavOpen(true)}
          endIcon={<Bars3Icon className="inline-block h-7 w-7 stroke-2" />}
          className={clsx(classes.hamburgerMenu, "block lg:hidden")}
        />
      </header>

      {/* Mobile: Mobile Menu Overlay */}
      <AnimatePresence mode="popLayout" initial={false}>
        {mobileNavOpen && <MobileMenu menuItems={menuItems} accountItems={accountItems} setOpen={setMobileNavOpen} />}
      </AnimatePresence>
    </Section>
  );
}

function MobileMenu({ menuItems = [], accountItems = [], setOpen = () => {} } = {}) {
  const AccountLink = ({ item }) => (
    <Link
      to={item.to || "#"}
      onClick={e => {
        if (item.onClick) {
          e.preventDefault();
          item.onClick(e);
        }
        setOpen(false);
      }}
      className={clsx(
        "inline-flex h-10 w-full items-center justify-center overflow-hidden whitespace-nowrap rounded-md border text-sm font-medium backdrop-blur-md [-webkit-backdrop-filter:blur(12px)]",
        item.label.startsWith("Log")
          ? "border-white/[.12] bg-black/75 text-gray-100"
          : "border-none bg-gray-300 text-black",
      )}
    >
      {item.label}
    </Link>
  );

  // fix: prevent underneath content scroll when mobile menu is open
  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";
    return () => {
      document.documentElement.style.overflowY = "";
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", bounce: 0, duration: 0.1 }}
      aria-label="Mobile Menu"
      className="fixed inset-0 z-50 overflow-y-auto bg-[linear-gradient(-120deg,rgba(20,20,20,0.6),rgba(38,38,38,0.6)_80%)] px-6 pb-10 backdrop-blur-[40px] backdrop-brightness-[40%] [-webkit-backdrop-filter:blur(40px)_brightness(40%)] lg:hidden"
    >
      <header className="mb-4 flex items-center justify-between pt-4">
        <HomeButton onClick={() => setOpen(false)} />

        {/* Hamburger Menu Close Buton */}
        <Button
          aria-label="Hamburger Menu Close Button"
          variant="custom"
          size="sm"
          placement="Navbar-hamburgerClose"
          onClick={() => setOpen(false)}
          endIcon={<XMarkIcon className="inline-block h-7 w-7 stroke-2" />}
          className={classes.hamburgerMenu}
        />
      </header>

      {/* Menu Items */}
      {menuItems.map((item, i) => (
        <div key={i}>
          {item.submenu ? (
            <>
              {item.submenu.map((section, i) =>
                section.label === "Apps" ? (
                  <>
                    <DropdownLink item={item} className="h-12" />
                    <div className="mb-4 flex flex-col gap-2">
                      {section.items.map((product, i) => (
                        <NavBarProduct
                          key={i}
                          product={product}
                          onClick={() => setOpen(false)}
                          className="ring-1 ring-black ring-opacity-5"
                          highlighted
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col">
                    {section.items.map((item, i) => (
                      <DropdownLink key={i} item={item} onClick={() => setOpen(false)} className="h-12" />
                    ))}
                  </div>
                ),
              )}
            </>
          ) : (
            <DropdownLink item={item} onClick={() => setOpen(false)} className="h-12" />
          )}
        </div>
      ))}

      <hr className="my-2 border-t border-white/5" />

      {/* Account */}
      <div className="mt-4 flex flex-col gap-3">
        {accountItems.map((item, i) => (
          <AccountLink key={i} item={item} />
        ))}
      </div>
    </motion.div>
  );
}

function HomeButton({ onClick = () => {} } = {}) {
  return (
    <Link
      to={window.location.origin}
      onClick={onClick}
      className="flex items-center space-x-2 text-lg font-bold tracking-wide text-gray-300 hover:text-waveryBlue"
    >
      <WaveryLogo className="h-11 w-11" />
      <span className="ml-2 font-sans text-[26px] font-bold text-gray-100 sm:ml-1 sm:text-[28px]">WAVERY</span>
    </Link>
  );
}

function NavBarLinks({ items = [] } = {}) {
  return (
    <ul className="flex items-center gap-4">
      {items.map((item, i) => (
        <li key={i} className="group relative">
          {item.submenu ? (
            <HoverDropdown>
              <HoverDropdown.Button className={classes.navLink}>{item.label}</HoverDropdown.Button>
              <HoverDropdown.Content className="space-y-[3px] p-2.5">
                {item.label === "Apps"
                  ? item.submenu.map((section, i) => (
                      <div key={i}>
                        {/* <HoverDropdown.Label>{section.label}</HoverDropdown.Label> */}
                        <div className="flex flex-col gap-1">
                          {section.items.map((product, i) => (
                            <NavBarProduct
                              key={i}
                              product={product}
                              className="p-4"
                              // highlighted
                            />
                          ))}
                        </div>
                      </div>
                    ))
                  : item.submenu.map((section, i) => (
                      <div key={i} className="flex flex-col gap-[3px]">
                        {section.items.map((item, i) => (
                          <Link
                            to={item.to}
                            className="flex w-full flex-col rounded-md p-2.5 text-sm text-gray-100 transition ease-out hover:bg-[hsla(0,0%,100%,0.05)]"
                          >
                            {item.label}
                            <span className="text-xs opacity-50">{item.description}</span>
                          </Link>
                        ))}
                      </div>
                    ))}
              </HoverDropdown.Content>
            </HoverDropdown>
          ) : (
            <Link to={item.to} className={classes.navLink}>
              {item.label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
}

function DropdownMenu({ items = [], header, footer } = {}) {
  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className={clsx(classes.navLink, "gap-1.5 hover:text-waveryBlue")}>
        Account
        <AnimatedChevron className="opacity-50" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-[120ms]"
        enterFrom="transform opacity-0 scale-[96%]"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-out duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-[94%]"
      >
        <Menu.Items className="absolute right-0 z-10 w-64 origin-top-right divide-y divide-white/10 rounded-lg border border-white/10 bg-[linear-gradient(-120deg,hsla(0,0%,8%,.6),rgba(38,38,38,.6)80%)] text-sm text-white shadow-[0_8px_16px_rgba(0,0,0,0.35)] outline-none ring-1 ring-black ring-opacity-5 backdrop-blur-md [-webkit-backdrop-filter:blur(12px)]">
          {header}
          <div className="py-1.5">
            {items.map((item, i) => (
              <Menu.Item key={i}>
                <Link
                  to={item.to || "#"}
                  className="flex items-center gap-x-1 whitespace-nowrap px-3 py-1.5 text-gray-100 transition ease-out hover:bg-white/[.05]"
                  onClick={item.onClick}
                >
                  <item.icon className="inline-block h-4 w-4 shrink-0 opacity-50" />
                  {item.label}
                </Link>
              </Menu.Item>
            ))}
          </div>
          {footer}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function DropdownLink({ item, className, onClick = () => {}, ...props }) {
  return (
    <Link
      to={item.to}
      onClick={onClick}
      className={clsx(
        "flex items-center space-x-2 whitespace-nowrap text-xl font-medium text-gray-200 [-webkit-tap-highlight-color:transparent] hover:text-waveryBlue lg:text-sm",
        className,
      )}
      {...props}
    >
      {item.icon && <item.icon className="mr-2 flex h-[18px] w-[18px] shrink-0 opacity-25" />}
      {item.label}
    </Link>
  );
}
