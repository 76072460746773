import * as RadixHoverCard from "@radix-ui/react-hover-card";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { createContext, useContext, useState } from "react";
import AnimatedChevron from "./AnimatedChevron";

const DropdownContext = createContext({
  open: false,
  setOpen: () => {},
});

export default function HoverDropdown({ onOpenChange = () => {}, children }) {
  const [open, setOpen] = useState(false);
  return (
    <DropdownContext.Provider value={{ open, setOpen }}>
      <RadixHoverCard.Root
        openDelay={0}
        closeDelay={100}
        open={open}
        onOpenChange={(open) => {
          onOpenChange(open);
          setOpen(open);
        }}
      >
        {children}
      </RadixHoverCard.Root>
    </DropdownContext.Provider>
  );
}

function HoverDropdownButton({ className, children, ...props }) {
  return (
    <RadixHoverCard.Trigger
      className={clsx(
        "group flex gap-1.5 cursor-pointer select-none",
        className
      )}
      {...props}
    >
      {children}
      <AnimatedChevron className="opacity-50" />
    </RadixHoverCard.Trigger>
  );
}

function HoverDropdownContent({ className, children, ...props }) {
  const { open } = useContext(DropdownContext);
  return (
    <AnimatePresence>
      {open && (
        <RadixHoverCard.Portal forceMount>
          <motion.div
            initial={{ opacity: 0, transform: "translateY(-2px)" }}
            animate={{ opacity: 1, transform: "translateY(0)" }}
            exit={{
              opacity: 0,
              transform: "translateY(-2px)",
              transition: { ease: "easeOut", duration: 0.1 },
            }}
            transition={{ ease: "easeOut", duration: 0.18 }}
          >
            <RadixHoverCard.Content
              className={clsx(
                // "bg-[rgba(15,15,15,.75)]",
                "bg-[linear-gradient(-120deg,hsla(0,0%,8%,.6),rgba(38,38,38,.6)80%)] backdrop-blur-md [-webkit-backdrop-filter:blur(12px)] shadow-[0_8px_16px_rgba(0,0,0,0.35)] ring-1 ring-black ring-opacity-5 rounded-lg",
                "border border-white/10",
                className
              )}
              {...props}
            >
              {children}
            </RadixHoverCard.Content>
          </motion.div>
        </RadixHoverCard.Portal>
      )}
    </AnimatePresence>
  );
}

function HoverDropdownLabel({ className, children, ...props }) {
  return (
    <h4
      className={clsx(
        "text-xl md:text-[13px] uppercase font-semibold tracking-wider text-waveryBlue mb-3",
        className
      )}
      {...props}
    >
      {children}
    </h4>
  );
}

HoverDropdown.Content = HoverDropdownContent;
HoverDropdown.Button = HoverDropdownButton;
HoverDropdown.Label = HoverDropdownLabel;
