import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "../components/HeroSection2";
import HeroSection3 from "./../components/HeroSection3";
import FeaturesSection from "./../components/FeaturesSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import StatsSection from "./../components/StatsSection";
// import StatsSection2 from "./../components/StatsSection2";
// import StatsSection3 from "./../components/StatsSection3";
// import ContentSection from "./../components/ContentSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
// import ClientsSection from "./../components/ClientsSection";
// import ClientsSection2 from "./../components/ClientsSection2";
// import CtaSection2 from "./../components/CtaSection2";
// import CtaSection3 from "./../components/CtaSection3";
import CtaSection from "./../components/CtaSection";
import EXISTSection from "../components/EXISTSection";
import { bg01, bg02 } from "../assets";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection2
        title="Unlimited Privacy-First"
        titleLower="AI Transcription."
        subtitle="World-class transcription UI with unparalleled Accuracy and Privacy."
        strapline="WhisperScript"
        size="lg"
        bgColor="bg-primary"
        bgImage={bg01}
        bgImageOpacity={0.5}
        bgImageClassName="-translate-y-[6%]"
        textColor="text-sky-100"
      />
      <HeroSection3
        title="Your All-in-One Transcription Solution"
        subtitle="Edit, export, and manage your audio transcriptions with WhisperScript's powerful, privacy-focused features."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        anchor="whisperscript"
      />
      <FeaturesSection
        title="The Ultimate Feature-Rich Transcription Tool"
        subtitle="With WhisperScript, listening through hours of interviews to find that one section of audio is a thing of the past. Quickly search through your audio to find just what you are looking for."
        strapline="WhisperScript:"
        size="md"
        bgColor="bg-primary"
        bgImage={bg02}
        bgImageOpacity={0.3}
        textColor="text-gray-200"
        anchor="features"
      />
      <FeaturesSection2
        title="Tailored for Your Needs"
        subtitle="WhisperScript supports multiple audio and video formats, batch processing, and offers customizable model parameters to fit your specific requirements."
        strapline="Transcription Workflows"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <StatsSection
        title="Proven Performance"
        subtitle="Trusted by professionals for efficient and secure transcriptions."
        strapline="Inspiring Results"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <EXISTSection
        title="Gefördert durch:"
        subtitle="Die Europäische Union fördert zusammen mit dem Bundesministerium für Wirtschaft und Klimaschutz über den Europäischen Sozialfonds plut (ESF Plus) das Programm 'Existenzgründungen aus der Wissenschaft (EXIST)' in Deutschland."
        strapline="Aufgrund eines Beschlusses des Deutschen Bundestages"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      <CtaSection
        title={
          <>
            Ready to Enhance Your <span className="text-gradient">Transcription</span> Workflow?
          </>
        }
        subtitle="Try WhisperScript for free and experience the difference."
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
        buttonLink="/pricing"
        buttonText="Get Started Today!"
        id="Home"
      />
    </>
  );
}

export default IndexPage;
