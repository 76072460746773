import React from "react";

function BackgroundImage(props) {
  return (
    <div
      className={
        "absolute bottom-0 left-0 right-0 top-0 z-0 bg-[image:var(--image)] opacity-[var(--opacity)] [background-position:center] [background-size:cover] [content-visibility:auto]" +
        (props.repeat ? " bg-auto bg-left-top bg-repeat" : "") +
        (!props.repeat ? " bg-cover bg-center" : "") +
        ` ${props.className}`
      }
      style={{
        "--image": `url("${props.image}")`,
        "--opacity": props.opacity,
      }}
    />
  );
}

export default BackgroundImage;
