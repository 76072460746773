import { Link } from "react-router-dom";
// import FeatureIcon from "./FeatureIcon";
// import Icon from "./Icon";
import clsx from "clsx";
import WaveryAppIcon from "./WaveryAppIcon";

export default function NavBarProduct({
  highlighted = false,
  product,
  onClick = () => {},
  className = "",
}) {
  return (
    <Link
      key={product.url}
      to={product.url}
      onClick={onClick}
      className={clsx(
        "hover:bg-slate-300/[.08] p-3 transition ease-out flex gap-2.5 items-center rounded-md [-webkit-tap-highlight-color:transparent] data-[highlighted]:bg-[hsla(0,0%,100%,0.05)]",
        className
      )}
      data-highlighted={highlighted || undefined}
    >
      <div className="flex items-center aspect-[52px] shrink-0">
        {/* <FeatureIcon color={product.iconBg}> */}
        <div className="flex overflow-hidden rounded">
          {/* <Icon src={product.image} alt="" width={52} height={52} /> */}
          <WaveryAppIcon className="w-[52px] h-[52px] pointer-events-none" />
        </div>
        {/* </FeatureIcon> */}
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <h5 className="text-lg font-semibold text-gray-200 md:text-md lg:text-sm whitespace-nowrap">
            {product.title}
          </h5>
          {product.tag && <Tag>{product.tag}</Tag>}
        </div>
        <p className="text-[15px] lg:text-[13px] md:text-[14px] leading-4 text-gray-400 line-clamp-2 w-full">
          {product.description}
        </p>
      </div>
    </Link>
  );
}

function Tag({ children }) {
  return (
    <div
      className={clsx(
        "px-2 py-0.5 text-xs text-[oklab(0.72_-0.04_-0.16)] capitalize rounded-full w-fit bg-[oklab(0.69_-0.06_-0.17_/_0.15)]"
        // "border-2 border-blue-400/60 shadow-[0_0_5px_0] shadow-blue-400/50"
      )}
    >
      <p className="font-bold truncate">{children}</p>
    </div>
  );
}
