import { CheckCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { plans } from "../constants";
import { useAuth } from "./../util/auth";
import { Link } from "./../util/router";
import AnimatedGradientBorderBox from "./AnimatedGradientBorderBox";
import BackgroundBlur from "./BackgroundBlur";
import Button from "./Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import WaveryAppIcon from "./WaveryAppIcon";

export default function PricingSection(props) {
  const auth = useAuth();

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      // bgImage={props.bgImage}
      // bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <BackgroundBlur className="-top-[76px] overflow-visible opacity-100" />
      <div className="container space-y-10">
        <div className="flex justify-center text-sky-700/70 hover:text-sky-400/70">
          <WaveryAppIcon className="h-[125px] w-[125px] sm:h-[150px] sm:w-[150px]" />
        </div>

        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />

        <div className="mx-auto grid max-w-[960px] grid-cols-1 justify-center gap-4 md:grid-cols-2 md:gap-0 lg:py-6">
          {plans.map((plan, i) => (
            <PricingCard key={i} plan={plan} authed={!!auth.user} />
          ))}
        </div>
      </div>
    </Section>
  );
}

function PricingCard({ plan, authed }) {
  return (
    <AnimatedGradientBorderBox
      animationSpeed={9}
      borderColor="rgb(82 82 91 / 0.55)"
      lineColor={plan.featured ? "rgb(161 161 170)" : "transparent"}
      lineOpacity={90}
      className={clsx(
        "group flex flex-col rounded-2xl shadow-[0_8px_16px_rgba(0,0,0,0.35)] backdrop-blur-[20px] backdrop-brightness-[40%] backdrop-hue-rotate-30 transition ease-out [-webkit-backdrop-filter:blur(20px)_brightness(40%)_hue-rotate(30deg)] data-[featured]:relative data-[featured]:h-auto data-[featured]:shadow-[0_12px_32px_rgba(255,255,255,0.125)] data-[featured]:md:-mx-2 data-[featured]:md:-my-6",
        "bg-[linear-gradient(-60deg,hsla(0,0%,8%,.6),rgba(0,4,15,.6)_80%)]",
        "data-[featured]:bg-[linear-gradient(-60deg,hsla(0,0%,8%,.6),rgba(0,4,15,.65)_80%),radial-gradient(600px_circle_at_100%_95%,rgba(56,189,248,.8),transparent_80%)]",
      )}
      data-featured={plan.featured || undefined}
      disabled={!plan.featured}
    >
      {/* {plan.featured && (
      <div className="absolute right-0 top-0 flex h-10 w-10 items-center justify-center">
        <BookmarkIcon className="inline-block h-6 w-6 text-orange-400" />
      </div>
    )} */}
      <div className="rounded-t-lg p-5 !pb-1 text-center lg:p-6">
        <span className="mb-4 inline-block rounded-full border border-white/5 bg-white/10 bg-opacity-50 px-3.5 py-1 text-[13px] font-semibold uppercase text-gray-200">
          {plan.name}
        </span>
        <div className="mb-1">
          <p className="my-1 inline-flex gap-[5px] text-[38px]/[40px] font-extrabold text-gray-100 lg:text-5xl">
            <span className="self-start text-xl">€</span>
            {plan.price}
            <span className="self-end pb-1 text-[15px]/[20px] font-semibold text-gray-200">{plan.billingCycle}</span>
          </p>
          <p className="mt-2.5 from-40% text-sm font-semibold text-gray-400 group-data-[featured]:bg-gradient-to-r group-data-[featured]:from-orange-400/95 group-data-[featured]:from-20% group-data-[featured]:to-yellow-300 group-data-[featured]:bg-clip-text group-data-[featured]:text-transparent">
            {plan.subtitle}
          </p>
        </div>
      </div>
      {plan.perks && (
        <div className={"mb-1 grow space-y-5 p-5 text-gray-300 lg:space-y-6 lg:p-6"}>
          <ul className="space-y-4 text-sm lg:text-base">
            {plan.perks.map((perk, index) => (
              <li className="flex items-center space-x-2" key={index}>
                <CheckCircleIcon className="inline-block h-5 w-5 text-green-500/90" />
                <span>{perk}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="px-5 pb-5 lg:px-6 lg:pb-6">
        {plan.disclaimer && <p className="mb-3 text-[13px] tracking-tight opacity-40">* {plan.disclaimer}</p>}
        <Button
          component={Link}
          to={
            authed
              ? `/purchase/${plan.id}?mode=${plan.mode}`
              : `/auth/signup?next=/purchase/${plan.id}?mode=${plan.mode}`
          }
          size="lg"
          variant={plan.featured ? "primary" : "dark"}
          isBlock={true}
        >
          {plan.buttonLabel}
        </Button>
      </div>
    </AnimatedGradientBorderBox>
  );
}
