import React from "react";

export default function WaveryLogo({ className, ...props }) {
  return (
    <svg
      alt="Wavery"
      className={className}
      viewBox="0 0 731 385"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_i_67_7)">
        <path
          d="M468.113 131.468C505.653 99.9942 577.742 116.409 611.9 139.09C537.534 70.1867 439.794 58.6684 344.774 99.4883C270.888 138.069 227.707 216.629 235.567 299.452C242.009 332.214 245.99 350.512 269.582 384.979H459.351C394.609 322.978 389.96 191.413 468.113 131.468Z"
          fill="url(#paint0_linear_67_7)"
          fillOpacity="0.7"
        />
        <path
          d="M611.9 139.09C547.887 42.7225 468.094 -19.2849 344.774 5.45405C166.412 51.2098 187.479 269.497 49.1885 357.957C24.119 373.993 1.09001 385.561 0.00326382 384.979C-1.08349 384.397 269.582 384.979 269.582 384.979C245.99 350.512 242.009 332.214 235.567 299.452C227.707 216.629 270.888 138.069 344.774 99.4883C439.794 58.6684 537.534 70.1867 611.9 139.09Z"
          fill="url(#paint1_linear_67_7)"
        />
        <path
          d="M731 384.979C639.549 370.472 569.011 353.951 502.033 286.968C479.418 264.351 420.625 180.799 468.113 131.468C389.96 191.413 394.609 322.978 459.351 384.979L731 384.979Z"
          fill="url(#paint2_linear_67_7)"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_67_7"
          x="0"
          y="0"
          width="731"
          height="387"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="hard-light"
            in2="shape"
            result="effect1_innerShadow_67_7"
          />
        </filter>
        <linearGradient
          id="paint0_linear_67_7"
          x1="390.816"
          y1="287.172"
          x2="252.035"
          y2="119.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A79AF" />
          <stop offset="1" stopColor="#003B53" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_67_7"
          x1="460.26"
          y1="14.7184"
          x2="-0.681183"
          y2="363.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#45C9FF" stopOpacity="0.9" />
          <stop offset="1" stopColor="#235A6D" stopOpacity="0.83" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_67_7"
          x1="628.154"
          y1="359.754"
          x2="405.166"
          y2="289.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#46A1D7" />
          <stop offset="1" stopColor="#1E3949" />
        </linearGradient>
      </defs>
    </svg>
  );
}
