import clsx from "clsx";

export default function AnimatedChevron({
  open,
  size = 16,
  angle = 40,
  lineHeight = 1,
  duration = 200, // apple: 1000
  timingFunction = "ease-out", // apple: cubic-bezier(0.86, 0, 0.07, 1)
  className = "",
}) {
  const style = {
    height: lineHeight,
    transitionTimingFunction: timingFunction,
    transitionDuration: duration + "ms",
  };
  return (
    <div
      className={clsx("relative inline-block", open && "group", className)}
      style={{
        width: size,
        height: size,
        "--angle": angle + "deg",
        "--size": size + "px",
        "--line-length": "calc(var(--size) / 2)",
        "--x-offset": `calc((var(--line-length) + ${
          lineHeight * 2
        }px) / 2 * (1 - cos(var(--angle))))`,
      }}
      data-state={
        typeof open === "boolean" ? (open ? "open" : "closed") : undefined
      }
    >
      {/* chevron left */}
      <span
        className={clsx(
          "absolute top-1/2 left-0 right-1/2 bg-white transition-all rounded-full translate-x-[--x-offset] rotate-[--angle] group-data-[state=open]:-rotate-[--angle] "
        )}
        style={style}
      />
      {/* chevron right */}
      <span
        className={clsx(
          "absolute top-1/2 left-1/2 right-0 bg-white transition-all rounded-full -translate-x-[--x-offset] -rotate-[--angle] group-data-[state=open]:rotate-[--angle]"
        )}
        style={style}
      />
    </div>
  );
}
