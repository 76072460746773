import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { wave01, waverySurfingFlow } from "../assets";
import { Link } from "../util/router";
import BackgroundBlur from "./BackgroundBlur";
import Button from "./Button";
import InlineVideo from "./InlineVideo";
import MacOSScreen from "./MacOSScreen";
import Section from "./Section";
import SectionHeaderHero from "./SectionHeaderHero";
import WaveryAppIcon from "./WaveryAppIcon";

function HeroSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      // bgImage={props.bgImage}
      // bgImageOpacity={props.bgImageOpacity}
      // bgImageClassName={props.bgImageClassName}
      textColor={props.textColor}
    >
      <BackgroundBlur className="-top-[76px] opacity-75" />
      <div className="container relative">
        <div className="text-center">
          <Link aria-label="Wavery App Icon" to="/pricing" className="relative mb-14 inline-block sm:mb-[66px]">
            <WaveryAppIcon className="h-[125px] w-[125px] sm:h-[150px] sm:w-[150px]" />
          </Link>
          <SectionHeaderHero
            title={props.title}
            titleLower={props.titleLower}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
        </div>

        <div className="flex flex-col items-center justify-center space-y-2 pb-16 pt-10 sm:flex-row sm:items-center sm:justify-center sm:space-x-2 sm:space-y-0">
          <Button
            component={Link}
            to="/pricing"
            size="xl"
            variant="primary"
            placement={"HeroSection2-CTA"}
            endIcon={<ArrowRightIcon className="inline-block h-5 w-5 opacity-70" />}
          >
            Start Free Trial Now
          </Button>
          <Button
            href={`${window.location.origin}/#features`}
            size="xl"
            variant="light"
            placement={"HeroSection2-learnMore"}
          >
            Learn more
          </Button>
        </div>
        <div className="pb-4 md:pb-0">
          <div className="relative mx-5 lg:mx-20">
            <div className="my-2 flex w-full items-center justify-center">
              <MacOSScreen wallpaperSrc={wave01} className="flex items-center justify-center brightness-110">
                <InlineVideo
                  src={waverySurfingFlow}
                  type="video/mp4"
                  alt="whisperscript video"
                  className="aspect-[1266/1080] w-[min(92vw,750px)] rounded-b-[1%] rounded-t-[1.3%] data-[loaded]:shadow-[0_8px_30px_rgba(0,0,0,0.6)]"
                />
              </MacOSScreen>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
