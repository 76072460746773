import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";
import { wave } from "../assets";
import Icon from "./Icon";

function CtaSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container space-y-10">
        <div className="text-center">
          <div className="relative mx-auto mb-10 inline-flex h-20 w-20 items-center justify-center text-emerald-500">
            <div className="absolute inset-0 rotate-6 scale-105 transform rounded-xl bg-sky-800" />
            <div className="absolute inset-0 -rotate-6 scale-105 transform rounded-xl bg-sky-100" />
            <div className="relative">
              <Icon src={wave} alt="" width={65} height={50} loading="lazy" />
              {/* <FireIcon className="inline-block w-10 h-10" /> */}
            </div>
          </div>
          <SectionHeader title={props.title} subtitle={props.subtitle} strapline={props.strapline} />
          {/* //dynamic render of button if props.button is true */}
          <div className="text-center">
            {props.buttonLink && (
              <Button
                component={Link}
                to={props.buttonLink}
                size="xl"
                placement={`${props.id}-CTA`}
                endIcon={<ArrowRightIcon className="inline-block h-5 w-5 opacity-70" />}
              >
                Let's get started
              </Button>
            )}
            {/* Dynamically render URI button for opening desktop app */}
            {props.customButton && (
              <div className="text-center">
                <a href={`${window.location.origin}/launchapp`}>
                  <Button size="lg" variant="simple" className="bg-white">
                    Launch WhisperScript on Desktop
                  </Button>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CtaSection;
