import clsx from "clsx";

export default function BackgroundBlur({ className }) {
  return (
    <div className={clsx("absolute flex size-full items-center justify-center overflow-hidden", className)}>
      <div className="relative h-full w-[2420px] shrink-0">
        <div class="absolute left-[280px] top-[190px] h-[900px] w-[1200px] rotate-0 rounded-full opacity-100 blur-[48px] [background:radial-gradient(ellipse_closest-side_at_center,#1CC8CDe1,#3166b410)]" />
        <div class="absolute left-1/2 top-[370px] h-[700px] w-[700px] -translate-x-[40%] rounded-full opacity-60 blur-[48px] [background:radial-gradient(ellipse_closest-side_at_center,#1CC8CD,#3166b410)]" />
        <div class="absolute right-[300px] top-[300px] h-[1000px] w-[940px] rotate-[30deg] rounded-full opacity-95 blur-[48px] [background:radial-gradient(ellipse_closest-side_at_center,#1CC8CDe1,#3166b409)]" />
      </div>
    </div>
  );
}
