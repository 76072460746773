import clsx from "clsx";
import { motion, useMotionTemplate, useMotionValue, useSpring } from "framer-motion";
import WaveryLogo from "./WaveryLogo";

export default function WaveryAppIcon({ className = "", ...props } = {}) {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const smoothMouseX = useSpring(mouseX, { stiffness: 150, damping: 30 });
  const smoothMouseY = useSpring(mouseY, { stiffness: 150, damping: 30 });

  function handleMouseMove({ currentTarget, clientX, clientY }) {
    const { left, top } = currentTarget.getBoundingClientRect();
    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  }

  return (
    <div
      onMouseMove={handleMouseMove}
      className={clsx(
        "group relative mx-auto flex items-center justify-center overflow-hidden rounded-[22%] bg-gradient-to-b from-[#1E1E1E] to-[#161616] to-60% shadow-2xl drop-shadow-[0_20px_80px_rgba(66,127,132,1)] transition duration-700 [box-shadow:inset_0_0.5px_0_0_rgba(255,255,255,0.17),0_6px_20px_5px_rgba(0,0,0,0.23)] hover:scale-[1.08] hover:drop-shadow-[0_0_70px_rgba(66,127,132,0.7)]",
        className,
      )}
      {...props}
    >
      <motion.div
        className="pointer-events-none absolute -inset-px rounded-xl opacity-0 transition duration-700 ease-out group-hover:opacity-100"
        style={{
          background: useMotionTemplate`
            radial-gradient(
              160px circle at ${smoothMouseX}px ${smoothMouseY}px,
              rgba(244 244 245 / 0.126),
              transparent 80%
            )
          `,
        }}
      />

      <div className="absolute inset-px rounded-[21%] bg-gradient-to-b from-[#1E1E1E] to-[#161616] to-60% opacity-[.6]" />
      <WaveryLogo className="m-[10%] h-full w-full transform-gpu brightness-105 drop-shadow-[0_0_50px_rgba(0,0,0,0.6)]" />
    </div>
  );
}
