import clsx from "clsx";
import { motion } from "framer-motion";

export default function AnimatedGradientBorderBox({
  animationSpeed = 12,
  borderColor = "rgb(82 82 91 / 0.6)",
  lineColor = "#adbbff",
  lineOpacity = 100,
  className,
  children,
  disabled = false,
  ...props
}) {
  return (
    <motion.div
      initial={{ "--conic-angle": "0deg" }}
      animate={!disabled && { "--conic-angle": "360deg" }}
      transition={{ ease: "linear", repeat: Infinity, duration: animationSpeed }}
      style={{
        "--border-color": borderColor,
        "--line-color": lineColor,
        "--line-opacity": lineOpacity / 100,
      }}
      className={clsx("relative ring-1 ring-black ring-opacity-5", className)}
      {...props}
    >
      {/* Animated Border */}
      <div
        className={clsx(
          "pointer-events-none absolute inset-0 transform-gpu border border-[--border-color] [-webkit-mask-composite:xor] [-webkit-mask:linear-gradient(#fff_0_0)_content-box,_linear-gradient(#fff_0_0)] [border-radius:inherit]",
          "[mask-composite:exclude] [mask-composite:xor] [mask-image:linear-gradient(#fff_0_0)_content-box,_linear-gradient(#fff_0_0)]",
          "before:absolute before:-inset-px",
          "before:bg-[conic-gradient(from_var(--conic-angle),transparent_5%,_var(--line-color)_20%,_transparent_25%)]",
          "before:opacity-[--line-opacity] before:drop-shadow-[0_0_10px_var(--line-color)] before:content-['']",
        )}
      />
      {/* Box Content */}
      <div className="contents [border-radius:inherit]">{children}</div>
    </motion.div>
  );
}
