import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
// import PricingSection2 from "./../components/PricingSection2";
import TestimonialsSection2 from "./../components/TestimonialsSection2";
// import ContentSection from "../components/ContentSection";
// import StripePricingTable from "../components/StripePricingTable";
import { bg01 } from "../assets";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title="Simple Pricing"
        subtitle="We offer a simple pricing model that is easy to understand and easy to use. No hidden fees, no surprises"
        strapline="WhisperScript"
        size="lg"
        bgColor="bg-primary"
        bgImage={bg01}
        bgImageOpacity={0.9}
        textColor="text-gray-100"
      />
      {/* <stripe-pricing-table
        pricing-table-id="prctbl_1Pzi2CIpxJzYULHMKBkHoWpk"
        publishable-key="pk_test_51PpX5BIpxJzYULHM3E3MwmmLRgtkk2ELuBx0yfknpo8xPaK2YvPggZANDI3FHBkLUSZbomF4bSyC8EawPJbBQtjc00Z7yzof1z"
      ></stripe-pricing-table> */}
      {/* <PricingSection2
        title="Pay Once, Transcribe Forever"
        subtitle="We know that you want a simple pricing model that makes sense for your business. That's why we've created a pricing model that is easy to understand and easy to use."
        strapline="Get it today"
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
      <TestimonialsSection2
        title=""
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      />
      {/* <ContentSection
        title="What's In the Box"
        subtitle="WhisperScript is a powerful tool that helps you manage your audio transcriptions with ease. Here are some of the features that you can expect from WhisperScript."
        size="md"
        bgColor="bg-primary"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-gray-100"
      /> */}
    </>
  );
}

export default PricingPage;
