import React from "react";
import Meta from "./../components/Meta";
import { Link } from "./../util/router.js";

function NotFoundPage(props) {
  return (
    <>
      <Meta title="404" />
      <div className="flex items-center justify-center p-12 m-auto text-white flex-col">
        <div className="text-[50px] font-bold">404</div>
        <div className="text-center font-medium text-gray-200 text-2xl mt-2.5">
          The page you're trying to load doesn't exist.
        </div>
        <Link to="/" className="mt-4 text-sky-300 font-normal hover:underline">
          Back to the home page.
        </Link>
      </div>
    </>
  );
}

export default NotFoundPage;
