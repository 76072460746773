import { useState } from "react";

export default function InlineVideo({
  src = undefined,
  type = undefined,
  alt = undefined,
  className = undefined,
  onLoaded = () => {},
  ...props
}) {
  const [loaded, setLoaded] = useState(false);

  const handleLoaded = () => {
    setLoaded(true);
    onLoaded(true);
  };
  return (
    <video
      src={src}
      type={type}
      alt={alt}
      className={className}
      autoPlay
      loop
      playsInline
      muted
      onLoadedData={handleLoaded}
      data-loaded={loaded || undefined}
      {...props}
    />
  );
}
